<ng-template #rt let-r="result" let-t="term">
    {{ r.name | titlecase }}
</ng-template>
<ng-container
    *ngIf="{
        results: searchParams.results | async,
        isvalid: searchParams.isValid | async,
        messages: searchParams.messages | async
    } as data">
    <div>
        <h2 class="search_content_titles" i18n="@@searchCriteria">Search Criteria</h2>
    </div>
    <div *ngIf="!(searchParams.search_loading | async)">
        <div class="european_rail_search_content background-46">
            <app-search-toggle>
                <app-validate-errors [messages]="data.messages"></app-validate-errors>
                <a
                    href="javascript:void(0)"
                    (click)="skipToResultContent()"
                    class="sr-only sr-only-focusable"
                    i18n="@@irlSkip"
                    >Skip to Result Content</a
                ><app-invoked-user></app-invoked-user>
                <app-irl-search></app-irl-search>
                <div class="flex-row-container flex-row-container--right">
                    <button
                        i18n-title
                        class="large-button large-button--search"
                        title="Start another search"
                        (click)="reSearch()">
                        <span *ngIf="this.searchParams.offlineRequest" i18n="@@irlSubmit">Submit</span>
                        <span *ngIf="!this.searchParams.offlineRequest" i18n="@@irlSearch">Search</span>
                    </button>
                </div>
            </app-search-toggle>
            <app-risk-alerts [countryCode]="'GB'"></app-risk-alerts>
        </div>
        <app-announcements
          [basketStatus]="0"
          page="results"
          [serviceType]="searchService.searchTypes">
        </app-announcements>
        <!-- search resuls -->
        <div class="list_header">
            <h3>
                1. {{ searchParams.originalUserSearch?.travellingFrom?.railstation?.name | titlecase }},
                {{ searchParams.originalUserSearch?.travellingTo?.railstation?.name | titlecase }}:
                {{ searchParams.originalUserSearch.outboundDepartureDateTime | sabsDate: 5 }}
            </h3>
            <div class="time_button_conatiner">
                <button
                    class="small-button small-button--margin"
                    (click)="getNextResultsPage(IrlJourneyType.SingleJourney, false)"
                    aria-label="Earlier Outbound Rail Times">
                    <i class="ico-chevron-left" aria-hidden="true"></i> <span i18n="@@railEarlier">Earlier</span>
                </button>
                <button
                    class="small-button small-button--margin later_rail_times"
                    (click)="getNextResultsPage(IrlJourneyType.SingleJourney)"
                    aria-label="Later Outbound Rail Times">
                    <i class="ico-chevron-right" aria-hidden="true"></i> <span i18n="@@railLater">Later</span>
                </button>
            </div>
        </div>
        <div
            appStickyBlock
            [margin]="70"
            [marginFix]="30"
            class="irl-class-header half-container flex-row-container">
            <div class="half-block"></div>
            <div
                aria-labelledby="irl-open-fare-rules-modal"
                role="button"
                class="half-block flex-row-container">
                <div
                    tabindex="0"
                    id="fareRulesStand"
                    alt="For more information regarding the fare conditions select
          here"
                    class="irl-class-header_item-class irl-class-header_item-class--2ndclass">
                    <span i18n="@@irl2ndclassHeader">2nd Class</span>
                </div>
                <div
                    tabindex="0"
                    class="irl-class-header_item-class irl-class-header_item-class--1stclass">
                    <span i18n="@@irl1stclassrHeader">1st class</span>
                    <section class="container"></section>
                </div>
            </div>
        </div>

        <app-irl-list
            resType="IrlJourneyType.SingleJourney"
            [from]="searchParams.travellingFrom"
            [to]="searchParams.travellingTo"
            [date]="outboundDateTime"
            [results]="data.results[0]?.outbound"
            [isReturn]="isReturn"
            (selectTicket)="setTicket($event, true)">
        </app-irl-list>
        <!-- this should be moved to above the returns -->
        <div class="list_header" *ngIf="isReturn">
            <h3>
                2. {{ searchParams.originalUserSearch?.travellingTo?.railstation?.name | titlecase }},
                {{ searchParams.originalUserSearch?.travellingFrom?.railstation?.name | titlecase }}:
                {{ searchParams.originalUserSearch.returnDepartureDateTime | sabsDate }}
            </h3>
            <div class="time_button_conatiner">
                <button
                    class="small-button small-button--margin"
                    (click)="getNextResultsPage(IrlJourneyType.ReturnJourney, false)"
                    aria-label="Earlier Return Rail Times">
                    <i class="ico-chevron-left" aria-hidden="true"></i> <span i18n="@@railEarlier">Earlier</span>
                </button>
                <button
                    class="small-button small-button--margin later_rail_times"
                    (click)="getNextResultsPage(IrlJourneyType.ReturnJourney)"
                    aria-label="Later Return Rail Times">
                    <i class="ico-chevron-right" aria-hidden="true"></i> <span i18n="@@railLater">Later</span>
                </button>
            </div>
        </div>
    </div>
    <div
        appStickyBlock
        [margin]="70"
        [marginFix]="30"
        class="irl-class-header half-container flex-row-container">
        <div class="half-block"></div>
        <div
            *ngIf="isReturn"
            aria-labelledby="irl-open-fare-rules-modal"
            role="button"
            class="half-block flex-row-container">
            <div
                tabindex="0"
                id="fareRulesStand"
                alt="For more information regarding the fare conditions select here"
                class="irl-class-header_item-class irl-class-header_item-class--2ndclass">
                <span i18n="@@irl2ndclassReturn">2nd Class</span>
            </div>
            <div
                tabindex="0"
                class="irl-class-header_item-class irl-class-header_item-class--1stclass">
                <span i18n="@@irl1stclassrReturn">1st class</span>
                <section class="container"></section>
            </div>
        </div>
    </div>
    <app-irl-list
        *ngIf="isReturn"
        resType="inbound"
        [from]="searchParams.travellingTo"
        [to]="searchParams.travellingFrom"
        [date]="returnDateTime"
        [results]="data.results[0]?.return"
        [isReturn]="isReturn"
        (selectTicket)="setTicket($event, false)">
    </app-irl-list>
</ng-container>
